<template>
    <div>
        <header class="header mo_header" style="display:none;">
            <div class="header_box">
                <div class="logo">
                    <a>
                        <h2>회원가입</h2>
                    </a>
                </div>
                <div class="right_menu">
                    <div class="mo_header_close_btn">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 6.2225L14.2225 0L16 1.7775L9.7775 8L16 14.2225L14.2225 16L8 9.7775L1.7775 16L0 14.2225L6.2225 8L0 1.7775L1.7775 0L8 6.2225Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </div>
        </header>
        <section class="login_main">
            <div class="login_main_box join_box2">
                <img src="/media/img/join_1_icon.png" alt="" />
                <h1>비밀번호 변경 완료</h1>
                <p>비밀번호가 변경되었습니다.</p>
                <p>로그인해주세요.</p>
                <router-link
                    :to="{
                        name: 'Login',
                    }"><button>로그인</button>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup () {}
});
</script>
<style>
.join_box2 img {width:92px;margin:0 auto;}
.join_box2 h1 {margin:32px 0;}
.join_box2 h3 {color:#fff; font-size:28px; font-weight:500;text-align:center;}
.join_box2 p {color: rgba(255, 255, 255, 0.6);text-decoration:none; font-size:20px; margin:8px 0 0px;text-align:center;}
.join_box2 p:nth-of-type(2) {text-align:center;color:#fff; font-size:16px; font-weight:400; opacity:0.6; transition:0.3s;}
</style>
<style src="@/assets/css/font.css"></style>
